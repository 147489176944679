import * as React from "react";

import { useMediaQuery } from "#/hooks/use-media-query";
import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { Button } from "#/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "#/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "#/ui/drawer";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "usehooks-ts";

export const DrawerDialogBase = ({
  children,
  className,
  closeVisible = true,
  ...props
}: {
  open?: boolean;
  className?: string;
  onOpenChange?: (isOpen: boolean) => void;
  children: React.ReactNode;
  closeVisible?: boolean;
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { height } = useWindowSize();
  const { t } = useTStream("common");

  if (isDesktop) {
    return (
      <Dialog {...props}>
        <DialogContent
          closeVisible={closeVisible}
          className={twMerge("p-0 max-h-[95vh] z-50 overflow-auto", className)}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer {...props}>
      <DrawerContent>
        <div
          className="px-4 space-y-4 overflow-auto"
          style={{
            maxHeight: height - height / 3,
          }}
        >
          {children}
        </div>
        {closeVisible && (
          <DrawerFooter className="pt-2 mt-4">
            <DrawerClose asChild>
              <Button variant="outline">{t("Cancel")}</Button>
            </DrawerClose>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export const DrawerDialog = ({
  children,
  title,
  description,
  right,
  classNameHeader,
  trigger,
  closeVisible = true,
  className,
  ...props
}: {
  description?: string | React.ReactNode;
  title: string | React.ReactNode;
  open?: boolean;
  right?: React.ReactNode;
  className?: string;
  classNameHeader?: string;
  closeVisible?: boolean;
  trigger?: React.ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  children: React.ReactNode;
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { height } = useWindowSize();
  const { t } = useTStream("common");
  const onClose = React.useCallback(
    () => props?.onOpenChange && props?.onOpenChange(false),
    [],
  );

  if (isDesktop) {
    return (
      <Dialog {...props}>
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent
          closeVisible={closeVisible}
          className={cn("max-h-[95vh] z-50 overflow-auto", className)}
        >
          <DialogHeader
            className={cn(
              "flex w-full items-center flex-row justify-between",
              classNameHeader,
              {
                "pr-3": !!right,
              },
            )}
          >
            <div className="">
              <DialogTitle className="text-xl">{title}</DialogTitle>
              {description && (
                <DialogDescription className="text-left text-muted">
                  {description}
                </DialogDescription>
              )}
            </div>
            {right}
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer {...props}>
      {trigger && <DrawerTrigger>{trigger}</DrawerTrigger>}
      <DrawerContent>
        <DrawerHeader
          className={cn(
            "text-left flex items-center flex-row justify-between",
            classNameHeader,
            {
              "pr-0": !!right,
            },
          )}
        >
          <div className="text-left">
            <DrawerTitle>{title}</DrawerTitle>
            {description && (
              <DrawerDescription className="text-left text-muted">
                {description}
              </DrawerDescription>
            )}
          </div>
          {right}
        </DrawerHeader>
        <div
          className="px-4 space-y-4 overflow-x-hidden overflow-y-auto"
          style={{
            maxHeight: height - height / 3,
          }}
        >
          {children}
        </div>
        {closeVisible && (
          <DrawerFooter className="pt-2 mt-4">
            <DrawerClose asChild>
              <Button variant="outline" onClick={onClose}>
                {t("Cancel")}
              </Button>
            </DrawerClose>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export const DrawerDialogStacked = ({
  children,
  title,
  description,
  trigger,
  closeVisible = true,
  className,
  asChild = true,
  classNameIcon,
  icon,
  ...props
}: {
  description?: string | React.ReactNode;
  title: string;
  open?: boolean;
  closeVisible?: boolean;
  icon?: React.ReactNode;
  trigger?: React.ReactNode;
  classNameIcon?: string;
  className?: string;
  onOpenChange?: (isOpen: boolean) => void;
  children?: React.ReactNode;
  asChild?: boolean;
}) => {
  const { t } = useTStream("common");
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog {...props}>
        {trigger && <DialogTrigger asChild={asChild}>{trigger}</DialogTrigger>}
        <DialogContent
          closeVisible={closeVisible}
          className={twMerge("max-h-[95vh] z-50 overflow-auto", className)}
        >
          <DialogHeader className="w-full text-center space-y-4 items-center">
            {icon && (
              <div
                className={cn(
                  "h-16 w-16 mb-2 rounded-full overflow-hidden items-center justify-center flex",
                  classNameIcon,
                )}
              >
                {icon}
              </div>
            )}
            <DialogTitle
              className={clsx("text-center", {
                "mt-10": !icon,
              })}
            >
              {title}
            </DialogTitle>
            {description && (
              <DialogDescription className="text-center text-muted">
                {description}
              </DialogDescription>
            )}
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer {...props}>
      {trigger && <DrawerTrigger>{trigger}</DrawerTrigger>}
      <DrawerContent>
        <DrawerHeader className="text-left space-x-1 flex items-center">
          {icon && (
            <div className="h-16 w-16 mb-2 border-2 rounded-full overflow-hidden items-center justify-center flex">
              {icon}
            </div>
          )}
          <div className="text-left">
            <DrawerTitle>{title}</DrawerTitle>
            {description && (
              <DrawerDescription className="text-left text-muted">
                {description}
              </DrawerDescription>
            )}
          </div>
        </DrawerHeader>
        <div className="px-2 md:px-4 space-y-4">{children}</div>
        {closeVisible && (
          <DrawerFooter className="pt-2 mt-4">
            <DrawerClose asChild>
              <Button variant="outline">{t("Cancel")}</Button>
            </DrawerClose>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
